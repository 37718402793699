/* eslint-disable react/prop-types */
import React from 'react';
import Loader from '../components/Loader';

export default function ClientButton({
  variant,
  size = 'small',
  text,
  onClick,
  classes,
  disabled = false,
  type = 'button',
  loading = false,
}) {
  const classSwitch = (key) => {
    switch (key) {
      case 'primary':
        return 'bg-client-text text-white ';
      case 'secondary':
        return 'hover:bg-portal-100 text-client-text border-client-text border-1 bg-white ';
      case 'link':
        return 'text-client-link bg-white border-client-link  ';
      case 'selected-link':
        return 'text-white bg-client-link border-client-link  ';
      case 'cancel':
        return 'text-white bg-client-cancelled border-client-cancelled  ';
      default:
        break;
    }
  };
  const customClass = (key) => {
    switch (key) {
      case 'large':
        return 'min-w-max py-3 px-4 text-[16px] sm:py-4 sm:px-4 sm:text-[18px] ';
      case 'normal':
        return 'min-w-72 sm:min-w-72 py-2 px-4 text-[18px] py-4 px-5 sm:py-4 sm:px-5 sm:text-[18px] ';
      case 'small':
        return 'min-w-30 sm:min-w-60 py-1.5 px-4 text-[14px] sm:py-2 sm:px-4 sm:text-[16px]  ';
      case 'xs':
        return 'min-w-[8rem] py-1 px-1 text-[12px] sm:py-2 sm:px-1 sm:text-[14px] ';
      default:
        break;
    }
  };
  return (
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={
        'btn -z-1 w- relative my-1 mx-2 rounded-full disabled:cursor-not-allowed   ' +
        (disabled || loading ? 'opacity-50 ' : '') +
        customClass(size) +
        classSwitch(variant) +
        classes
      }
      type={type}
    >
      {loading ? (
        <svg className='h-6 w-6 animate-spin stroke-gray-500' viewBox='0 0 256 256'>
          <line x1='128' y1='32' x2='128' y2='64' strokeLinecap='round' strokeLinejoin='round' strokeWidth='24'></line>
          <line
            x1='195.9'
            y1='60.1'
            x2='173.3'
            y2='82.7'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='24'
          ></line>
          <line
            x1='224'
            y1='128'
            x2='192'
            y2='128'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='24'
          ></line>
          <line
            x1='195.9'
            y1='195.9'
            x2='173.3'
            y2='173.3'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='24'
          ></line>
          <line
            x1='128'
            y1='224'
            x2='128'
            y2='192'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='24'
          ></line>
          <line
            x1='60.1'
            y1='195.9'
            x2='82.7'
            y2='173.3'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='24'
          ></line>
          <line x1='32' y1='128' x2='64' y2='128' strokeLinecap='round' strokeLinejoin='round' strokeWidth='24'></line>
          <line
            x1='60.1'
            y1='60.1'
            x2='82.7'
            y2='82.7'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='24'
          ></line>
        </svg>
      ) : (
        text
      )}
    </button>
  );
}
