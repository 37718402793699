import React, { useState } from 'react';
import ModalWithHeading from '../../../components/ModalWithHeading';
import ClientButton from '../../../components/ClientButton';
import RatingStars from '../../../components/RatingStars';
import { updateOrderApi } from '../../../redux/Reducers/MyAccountReducer';
import Loader from '../../../components/Loader';
import toast from 'react-hot-toast';

export default function RatingModal({ ratingModal, setRatingModal, orderSelected, triggerRefresh }) {
  const [loading, setLoading] = useState(false);
  const [tenantRating, setTenantRating] = useState(null);
  const [workerRating, setWorkerRating] = useState(null);

  const onSaveRatingFn = async () => {
    setLoading(true);
    const payload = {
      worker_rating: { rating: workerRating },
      tenant_rating: { rating: tenantRating },
    };
    try {
      const response = await updateOrderApi(orderSelected?.id, payload);
      if (response?.status === 200 || response?.status === 201 || response?.status === 204) {
        setRatingModal(false);
        triggerRefresh();
        toast.success(response?.message || 'Rating saved successfully');
      } else {
        toast.error(response?.message || 'Something went wrong');
      }
    } catch (error) {
      toast.error(error?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalWithHeading modalOpen={ratingModal} setModalOpen={setRatingModal} classes='max-w-2xl'>
      <div className='mx-8'>
        <div className='my-4 flex-row justify-center'>
          {loading ? (
            <Loader text='processing...' classes='h-60' />
          ) : (
            <div>
              <div className='mb-10 flex-row items-center space-y-5 text-center'>
                <span className='go-text-20'>
                  <span className='font-normal'>Rate </span>
                  <span>{orderSelected?.worker?.first_name ? `${orderSelected?.worker?.first_name}` : 'Provider'}</span>
                </span>
                <RatingStars
                  justify='justify-center'
                  ratingValue={workerRating}
                  onClick={(val) => setWorkerRating(val)}
                  size={36}
                />
              </div>
              <div className='mb-10 flex-row items-center space-y-5 text-center'>
                <span className='go-text-20'>
                  <span className='font-normal'>Rate </span>
                  <span>{orderSelected?.tenant?.name ? `${orderSelected?.tenant?.name}` : 'Tenant'}</span>
                </span>
                <RatingStars
                  justify='justify-center'
                  ratingValue={tenantRating}
                  onClick={(val) => setTenantRating(val)}
                  size={36}
                />
              </div>
            </div>
          )}
        </div>
        <div className='flex justify-center py-5 '>
          <ClientButton
            variant='secondary'
            text='Cancel'
            classes=''
            onClick={() => {
              setRatingModal(false);
            }}
          />
          <ClientButton
            variant='primary'
            text='Save'
            classes=''
            disabled={tenantRating === null || workerRating === null}
            onClick={onSaveRatingFn}
          />
        </div>
      </div>
    </ModalWithHeading>
  );
}
