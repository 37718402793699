import React, { useState } from 'react';

export default function SearchInput({ placeholder, onchange }) {
  const [value, setvalue] = useState(null);
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onchange(value);
    }
  };
  return (
    <div className='relative mt-1'>
      <input
        id='suffix'
        className='go-textblacknormal-16 relative w-full cursor-default rounded border border-gray-200 bg-white py-[7px] pl-3 pr-10 text-left sm:py-2'
        type='text'
        defaultValue={value}
        onChange={(e) => {
          setvalue(e.target.value);
        }}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
      />
      <div
        className=' absolute inset-0 left-auto flex items-center'
        role='button'
        tabIndex={0}
        onClick={() => onchange(value)}
      >
        <span className='bg-client-iconbg  absolute inset-y-0 right-0 m-0.5 flex items-center rounded p-2'>
          <span className='text-client-link h-5 w-5' aria-hidden='true'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='h-5 w-5'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
              />
            </svg>
          </span>
        </span>
      </div>
    </div>
  );
}
