import React from 'react';
import { Link } from 'react-router-dom';

export default function LinkButton({ onClick, variant = 'primary', text = 'Ok', disabled = false, loading }) {
  return (
    <Link
      className={
        'w-full rounded border border-[#548ff7] py-2 px-4 text-center text-sm font-normal ' +
        (variant === 'primary' ? 'bg-[#548ff7] text-white' : ' bg-white text-[#548ff7]') +
        (disabled ? ' pointer-events-none cursor-not-allowed opacity-80' : '')
      }
      onClick={() => {
        onClick();
      }}
      to='#'
    >
      <div className='flex justify-center'>
        {loading ? (
          <svg
            className={'h-6 w-6 animate-spin ' + (variant === 'primary' ? ' stroke-white' : 'stroke-[#548ff7]')}
            viewBox='0 0 256 256'
          >
            <line
              x1='128'
              y1='32'
              x2='128'
              y2='64'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='24'
            ></line>
            <line
              x1='195.9'
              y1='60.1'
              x2='173.3'
              y2='82.7'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='24'
            ></line>
            <line
              x1='224'
              y1='128'
              x2='192'
              y2='128'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='24'
            ></line>
            <line
              x1='195.9'
              y1='195.9'
              x2='173.3'
              y2='173.3'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='24'
            ></line>
            <line
              x1='128'
              y1='224'
              x2='128'
              y2='192'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='24'
            ></line>
            <line
              x1='60.1'
              y1='195.9'
              x2='82.7'
              y2='173.3'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='24'
            ></line>
            <line
              x1='32'
              y1='128'
              x2='64'
              y2='128'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='24'
            ></line>
            <line
              x1='60.1'
              y1='60.1'
              x2='82.7'
              y2='82.7'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='24'
            ></line>
          </svg>
        ) : (
          text
        )}
      </div>
    </Link>
  );
}
