import { Popover, Transition } from '@headlessui/react';
import { CalendarIcon, ChevronUpDownIcon, FunnelIcon } from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ClientButton from '../../../components/ClientButton';
import { statusDropdown } from '../../../mockdata/myOrder';
import moment from 'moment';

export default function OrderFiltersPopover({ onFiltersChange }) {
  const [startDate, setStartDate] = useState(null);
  const [eventType, setEventType] = useState('pickup');
  const onChange = (date) => {
    setStartDate(date);
  };

  return (
    <div className=' z-1 w-full max-w-sm '>
      <Popover className='relative'>
        {({ open, close }) => (
          <>
            <Popover.Button className='go-textblacknormal-16 relative w-full cursor-default rounded border border-gray-200 bg-white py-2 pl-3 pr-12 text-left'>
              <span className='block truncate'>
                Date :{' '}
                {startDate
                  ? `${eventType === 'pickup' ? 'Pickup' : 'Delivery'}, ${moment(startDate).format('MM-DD-YYYY')}`
                  : 'All'}
              </span>
              <span className='bg-client-iconbg pointer-events-none absolute inset-y-0 right-0 m-0.5 flex items-center rounded p-2'>
                <span className='text-client-link h-5 w-5' aria-hidden='true'>
                  <CalendarIcon />
                </span>
              </span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute z-10 mt-3 w-screen max-w-sm -translate-x-2/3 transform px-4 sm:px-0 lg:left-1/2 lg:max-w-xs lg:-translate-x-1/2'>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5'>
                  <div className='relative grid grid-cols-3 gap-8 bg-white pt-7 pl-7'>
                    <label className='flex'>
                      <input
                        onChange={(e) => {
                          setEventType('pickup');
                        }}
                        defaultChecked={eventType == 'pickup'}
                        checked={eventType == 'pickup'}
                        type='radio'
                        name='pickupanddelivery'
                        className='form-radio mt-1.5'
                      />
                      <span className='go-textblacknormal-16 ml-2 pt-0.5'>Pickup</span>
                    </label>
                    <label className='flex'>
                      <input
                        onChange={(e) => {
                          setEventType('delivery');
                        }}
                        defaultChecked={eventType == 'delivery'}
                        type='radio'
                        name='pickupanddelivery'
                        checked={eventType == 'delivery'}
                        className='form-radio mt-1.5'
                      />
                      <span className='go-textblacknormal-16 ml-2 pt-0.5'>Delivery</span>
                    </label>
                  </div>
                  <div className='relative grid gap-8 bg-white p-7 lg:grid-cols-1'>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        onChange(date);
                      }}
                      inline
                    />
                  </div>
                  <div className='flex justify-between bg-gray-50 p-4'>
                    <ClientButton
                      size='xs'
                      text='Clear'
                      variant='link'
                      onClick={() => {
                        close();
                        setStartDate(null);
                        onFiltersChange('range', {
                          type: 'All',
                          date: null,
                        });
                      }}
                    />
                    <ClientButton
                      size='xs'
                      text='Apply'
                      variant='primary'
                      onClick={() => {
                        close();
                        onFiltersChange('range', {
                          type: eventType,
                          date: startDate,
                        });
                      }}
                    />
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
