import React from 'react';
import { Rating } from 'react-simple-star-rating';
import { roundedRatingValue } from './Utils';

export default function RatingStars({ ratingValue, justify = '', onClick, readonly = false, size = 22 }) {
  console.log('object :>> ', justify);
  return (
    <div
      style={{
        direction: 'ltr',
        fontFamily: 'sans-serif',
        touchAction: 'none',
      }}
    >
      <div className={'flex ' + justify}>
        <Rating
          readonly={readonly}
          fillColor='#23292e'
          emptyColor='#cccccc'
          size={size}
          onClick={onClick}
          initialValue={roundedRatingValue(ratingValue)}
          SVGclassName='inline-block'
          allowFraction={true}
        />
        <div className='go-textblack-14 mt-1 ml-2'>{ratingValue ? parseFloat(ratingValue).toFixed(1) : 0}</div>
      </div>

      {/* {ratingValue && ratingValue > 0 && (
        
      )} */}
    </div>
  );
}
